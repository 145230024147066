import React, { useState } from 'react';
import { ethers } from 'ethers';
import { useConnectWallet, useSetChain } from '@web3-onboard/react';
import onboard from './onboard';
import './App.css';

function App() {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [{ connectedChain, settingChain }, setChain] = useSetChain();
  const [network, setNetwork] = useState('0x1'); // Default to Ethereum Mainnet
  const [isAddressValid, setIsAddressValid] = useState(false);
  let ethersProvider;

  if (wallet) {
    ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any');
  }

  const handleConnect = () => {
    connect();
  };

  const handleDisconnect = () => {
    disconnect(wallet);
  };

  const handleNetworkChange = async (event) => {
    const chainId = event.target.value;
    setNetwork(chainId);
    await setChain({ chainId });
  };

  const handleAddressChange = (event) => {
    const address = event.target.value;
    if (ethers.utils.isAddress(address)) {
      setIsAddressValid(true);
    } else {
      setIsAddressValid(false);
    }
  };

  const sendTransaction = async () => {
    const targetAddress = document.getElementById('targetAddress').value;
    const amount = document.getElementById('amount').value;
    const hexData = document.getElementById('hexData').value;

    try {
      const tx = {
        to: targetAddress,
        value: ethers.utils.parseEther(amount),
        data: hexData,
      };

      const signer = ethersProvider.getSigner();
      const transactionResponse = await signer.sendTransaction(tx);
      document.getElementById('status').innerText = `Transaction sent! Hash: ${transactionResponse.hash}`;
    } catch (error) {
      console.error(error);
      document.getElementById('status').innerText = `Error: ${error.message}`;
    }
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <div className="container">
      <img src="logo192.png" alt="logo" className="logo" />
      <h1>Send Custom Transaction</h1>
      <p>To use, just connect your wallet and send the transaction.</p>
      {wallet ? (
        <div>
          <p id="status">Connected to {wallet.accounts[0].address}</p>
          <button className="connect-button" onClick={handleDisconnect}>Disconnect</button>
        </div>
      ) : (
        <button className="connect-button" onClick={handleConnect} disabled={connecting}>
          {connecting ? 'Connecting...' : 'Connect Wallet'}
        </button>
      )}
      {wallet && (
        <div>
          <label htmlFor="network">Select Network:</label>
          <select id="network" value={network} onChange={handleNetworkChange}>
            <option value="0x1">Ethereum Mainnet</option>
            <option value="0x38">Binance Smart Chain</option>
            <option value="0x89">Polygon</option>
            <option value="0xa86a">Avalanche C-Chain</option>
            <option value="0xfa">Fantom Opera</option>
            <option value="0xa4b1">Arbitrum One</option>
            <option value="0xa">Optimism</option>
            <option value="0x2105">Base</option>
            <option value="0x64">Gnosis Chain</option>
            <option value="0x2329">Blast</option>
          </select>
        </div>
      )}
      <div id="walletInfo">
        <form id="txForm">
          <label htmlFor="targetAddress">Target Contract Address:</label><br />
          <input type="text" id="targetAddress" name="targetAddress" placeholder="Enter contract address" onFocus={handleFocus} onChange={handleAddressChange} /><br /><br />
          <label htmlFor="amount">Transaction Value:</label><br />
          <input type="text" id="amount" name="amount" placeholder="Enter Value in Base Currency" onFocus={handleFocus} /><br /><br />
          <label htmlFor="hexData">Hex Data:</label><br />
          <input type="text" id="hexData" name="hexData" placeholder="Enter hex Calldata" onFocus={handleFocus} /><br /><br />
          <button type="button" className="send-button" onClick={sendTransaction} disabled={!isAddressValid}>Send Transaction</button>
        </form>
      </div>
      <div className="footer">
        <a href="https://twitter.com/calldataXYZ" target="_blank" rel="noopener noreferrer">
          <img src="twitter-icon.png" alt="Twitter" className="social-icon" />
        </a>
        <a href="https://github.com/CalldataXYZ" target="_blank" rel="noopener noreferrer">
          <img src="github-icon.png" alt="GitHub" className="social-icon" />
        </a>
      </div>
    </div>
  );
}

export default App;