import { init } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';

const injected = injectedModule();

const web3Onboard = init({
  apiKey: 'YOUR_API_KEY', // Replace with your Blocknative API key
  wallets: [injected],
  chains: [
    {
      id: '0x1', // Ethereum Mainnet
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: 'https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID' // Replace with your Infura Project ID
    },
    {
      id: '0x38', // Binance Smart Chain
      token: 'BNB',
      label: 'Binance Smart Chain',
      rpcUrl: 'https://bsc-dataseed.binance.org/'
    },
    {
      id: '0x89', // Polygon
      token: 'MATIC',
      label: 'Polygon',
      rpcUrl: 'https://polygon-rpc.com/'
    },
    {
      id: '0xa86a', // Avalanche
      token: 'AVAX',
      label: 'Avalanche C-Chain',
      rpcUrl: 'https://api.avax.network/ext/bc/C/rpc'
    },
    {
      id: '0xfa', // Fantom
      token: 'FTM',
      label: 'Fantom Opera',
      rpcUrl: 'https://rpcapi.fantom.network'
    },
    {
      id: '0xa4b1', // Arbitrum
      token: 'ETH',
      label: 'Arbitrum One',
      rpcUrl: 'https://arb1.arbitrum.io/rpc'
    },
    {
      id: '0xa', // Optimism
      token: 'ETH',
      label: 'Optimism',
      rpcUrl: 'https://mainnet.optimism.io'
    },
    {
      id: '0x2105', // Base
      token: 'ETH',
      label: 'Base',
      rpcUrl: 'https://mainnet.base.org'
    },
    {
      id: '0x64', // Gnosis Chain (formerly xDai)
      token: 'xDAI',
      label: 'Gnosis Chain',
      rpcUrl: 'https://rpc.gnosischain.com'
    },
    {
      id: '0x2329', // Blast
      token: 'BLT',
      label: 'Blast',
      rpcUrl: 'https://rpc.blastchain.org'
    }
  ],
  appMetadata: {
    name: 'Custom Transaction Sender',
    icon: '<svg><svg/>', // Placeholder icon
    description: 'Send custom transactions using web3-onboard'
  }
});

export default web3Onboard;